.slick-prev,
.slick-next {
  &:before,
  &:before {
    border-style: solid;
    border-width: 0.1em 0.1em 0 0;
    content: '';
    display: inline-block;
    height: 1em;
    width: 1em;
    top: 0;
    left: 0;
    position: relative;
    transform: rotate(-135deg);
    vertical-align: top;
    color: $brand-color;
  }
}

.slick-prev {
  left: -60px;
}

.slick-next {
  right: -60px;
}

.slick-next:before {
  left: 0;
  transform: rotate(45deg);
}

.slick-arrow {
  height: 35px;
  width: 35px;

  @media only screen and (max-width: 424px) {
    height: 28px;
    width: 28px;
  }
}

.slick-dots {
  bottom: -40px;

  li {
    margin: 0 1px;

    button:before {
      font-size: 15px;
    }
  }
}

.slick-slide:focus,
.slick-slide img:focus {
  outline: none;
}