.page-nyheter main {
  width: 80%;
  max-width: 800px;
  margin: 3em auto;

  article {
    margin-bottom: 4em;
  }

  .news-date {
    margin: 0;
    color: $dark-grey;
    font-size: 12px;
    font-weight: 300;
  }

  h1,
  h2,
  h3 {
    line-height: 1.25em;
  }

  h1 {
    font-size: 43px;
    position: absolute;
    left: -9999px;
  }

  h2 {
    margin: 0 0 0.4em;
  }

  img {
    width: 150px;
    height: auto;
  }

  article a {
    display: block;
    color: $text-color;

    &:hover,
    &:active,
    &:visited {
      text-decoration: none;

      &:hover h2 {
        color: $brand-color;
        text-decoration: underline;
      }

      &:hover p {
        color: $heading-color;
      }
    }
  }
  @include for-phone-only {
    max-width: unset;
    margin: 2em 0 0;
  }
}