// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

header {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  
    nav {
      flex: 2 0;
      font-size: 12px;
      font-weight: bold;
  
      a {
        padding: 5px 0;
        margin: 0 15px;
        color: $text-color;
        border-bottom: transparent 4px solid;

        &:active, &:focus {
          text-decoration: none;
        }
  
        &:hover {
          color: $brand-color;
          border-bottom-color: $brand-color;
        }
      }
  
      li.active a {
        color: $brand-color;
        border-bottom-color: $brand-color;
      }
    }
  
    .logo {
      position: absolute;
      right: calc(50% - (25px / 2));
      top: 1rem;
  
      a {
        display: block;
        padding: 10px;
  
        svg path {
          transition: all ease 0.2s;
        }
      }
  
      svg:hover path {
        fill: $brand-color;
      }
    }
  }