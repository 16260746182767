// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.button {
    align-self: center;
    font-size: 14px;
    padding: 10px;
    color: white;
    border: 0;
    border-radius: 3px;
    clear: both;
    text-align: center;
    margin-top: auto;
    transition: all ease 0.2s;
    &:hover {
        cursor: pointer;
        opacity: 0.9;
        transition: all ease 0.2s;
    }
    &-green {
        background: $green;
    }
    &-blue {
        background: $brand-color;
    }
}