// -----------------------------------------------------------------------------
// This file contains all styles related to the grid of the site/application.
// -----------------------------------------------------------------------------

.col-2 {
    width: 48%;
}
.col-3 {
    width: 30%;
}
.col-4 {
    width: 22%;
}
.image img {
    max-width: 100%;
}