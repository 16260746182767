.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.8);
  display: none;

  &-show {
    display: flex;
    z-index: 5;
  }
}

.overlay-container {
  @media only screen and (min-width: 769px) {
    display: flex;
  }
  position: relative;
  background: white;
  width: 80%;
  max-width: 1000px;
  max-height: 768px;
  margin: 0;
  overflow: scroll;

  h2 {
    font-size: 43px;
    font-weight: lighter;
    margin-top: 0;
    color: #20292b;
  }

  p {
    font-size: 18px;
    line-height: 1.66em;
    margin-top: 0;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    height: 2.5em;
    width: 2.5em;
    padding: 1em;
    text-indent: -9999px;
    background: transparent url(/images/icon-close.svg) no-repeat center;
    background-size: 1.5em auto;
    border: 0;
    color: $brand-color;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
      transition: all ease 0.2s;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 8%;

    @media only screen and (min-width: 769px) {
      border-left: transparent 1px solid;
      border-right: transparent 1px solid;

      &:first-of-type {
        border-right: 1px solid $orange;
      }
    }
  }

  .customer {
    text-align: center;

    p {
      font-size: 15px;
    }

    strong {
      color: black;
      font-size: 16px;
    }

    img {
      margin: 0 auto;
      height: 110px;
      width: 110px;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  .popover {
    align-self: center;
    max-width: 270px;
    margin-top: 1rem;
  }

  .contactform {
    width: 100%;
    margin: 4rem 0 2rem;
    max-width: 100%;

    form {
      width: 100%;
      max-width: 320px;
      margin-bottom: 0.5em;
    }

    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="tel"],
    textarea {
      width: 100%;
      max-width: 320px;
      border: 1px solid #9b9b9b;
    }

    .button {
      min-width: 110px;
    }

    p {
      font-size: 14px;
    }

    .terms {
      font-size: 10px;
      font-style: italic;
    }

    &__full {
      input[type="text"],
      input[type="email"],
      input[type="number"],
      input[type="tel"] {
        background: white;
        border: 0;
        border-bottom: 1px solid black;
        border-radius: 0;
        padding: 5px 0;
      }
      textarea {
        border: 0;
        padding: 5px;
        background: $mid-grey;
      }

      .button {
        position: unset;
        right: unset;
        top: unset;
      }
    }
  }
}