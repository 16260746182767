.page-cases main {
  width: 80%;
  max-width: 800px;
  margin: 3em auto;

  article {
    margin: 2.5em 0;
  }

  h1 {
    font-size: 43px;
  }

  h2 {
    margin: 0 0 0.4em;
  }

  h1,
  h2,
  h3 {
    line-height: 1.25em;
  }

  img {
    width: 200px;
    height: auto;
  }

  article {
    .case-title {
      margin: 0 0 0.4em;
      color: $dark-grey;
      font-size: 16px;
      font-weight: 300;
    }

    a {
      display: block;
      color: $text-color;

      &:hover,
      &:active,
      &:visited {
        text-decoration: none;
      }

      &:hover h2 {
        color: $brand-color;
        text-decoration: underline;
      }

      &:hover p {
        color: $heading-color;
      }
    }
  }

  @include for-phone-only {
    max-width: unset;
    margin: 2em 0 0;
  }
}