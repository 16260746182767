.link-list {
  li a {
    position: relative;

    &:after {
      content: "+";
      display: block;
      right: 0;
      padding: 1rem 0;
      position: absolute;
      font-size: 2em;
      font-weight: lighter;
      color: $brand-color;
      top: 50%;
      transform: translateY(-56%);
    }

    &:before {
      background: #174886;
    }
  }
}

.checklist {
  li {
    position: relative;
    padding-left: 1em;

    &:before {
      content: "";
      position: absolute;
      left: 3px;
      top: 0.45em;
      display: inline-block;
      width: 0.25em;
      height: 0.5em;
      border: solid $text-color;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}