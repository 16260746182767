// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
  display: flex;
  justify-content: center;
  color: white;
  background: #4a4a4a;
  font-size: 12px;
  line-height: 18px;
  padding: 2rem 1rem;

  div {
    width: 150px;
    padding: 0 1.3rem;
    border-left: 1px solid white;

    &:first-child {
      min-width: 300px;
    }
  }

  a,
  a:hover,
  a:active {
    color: white;
  }

  .language {
    text-align: right;
    border-left: unset;
  }
  @include for-phone-only {
    display: block;

    div,
    .address {
      width: 100%;
      padding: 1.3rem;
      border-left: unset;
      border-bottom: 1px solid white;
    }

    .language {
      text-align: unset;
      border-bottom: unset;
    }
  }
}