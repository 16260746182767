// -----------------------------------------------------------------------------
// This file contains styles that are specific to the index page.
// -----------------------------------------------------------------------------

.page-index {
  background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
  background-size: 100% 1024px;

  // Temporary styles 
  .start-8,
  .start-8 + hr,
  .start-11,
  .start-11 + hr {
    display: none;
  }
}

section {
  display: flex;
  flex-direction: column;
  padding: 5rem 5% 6rem;

  h1 {
    font-size: 44px;
    font-weight: lighter;
    text-align: center;
    color: #363636;
    margin: 2em 0;

    strong {
      line-height: 1em;
      font-size: 2em;
      font-weight: 800;
    }

    @include for-phone-only {
      font-size: 1.1em;
    }
  }

  h2 {
    margin-top: 0;
    font-size: 43px;
    align-self: center;

    @include for-phone-only {
      line-height: 1.2em;
      margin-bottom: 0.5em;
    }
  }

  .teasers {
    display: flex;
    justify-content: space-between;

    .teaser {
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: 3px;

      img {
        width: 100%;
        height: auto;
      }

      p {
        font-size: 14px;
      }
    }

    .col-3 {
      .checklist {
        min-width: 75%;
        max-width: 230px;
        align-self: center;
      }

      .button {
        @include for-phone-only {
          padding-left: 5px;
          padding-right: 5px;
          width: 100%;
          width: 200px;
        }
      }
    }

    .col-4 {
      img {
        max-width: 300px;
        margin: 0 auto;
      }

      p:last-of-type {
        margin-bottom: 2em;
      }

      .button {
        padding-left: 5px;
        padding-right: 5px;
        width: 100%;
        margin-top: auto;

        @include for-phone-only {
          width: 200px;
        }
      }
    }

    @include for-phone-only {
      display: block;

      .col-3,
      .col-4 {
        width: unset;
        margin-bottom: 2rem;
      }
    }
  }

  &.start-animation {
    align-items: center;

    .phone {
      position: relative;
      height: 919px;
      width: 455px;
      background: transparent url("/images/iphone-frame.png") no-repeat 0 0;
      background-size: 100% auto;
      border-radius: 72px;
      margin-bottom: 3rem;

      @include for-phone-only {
        width: 273px;
        height: 551.4px;
        border-radius: 43px;
      }

      .screens {
        height: 856px;
        width: 397px;
        background: #f2f2f2;
        background-attachment: fixed;
        margin: 28px 30px;
        background-size: 100% auto;
        border-radius: 39px;
        overflow: scroll;

        @include for-phone-only {
          height: 513.6px;
          width: 238.2px;
          margin: 16.8px 18px;
          border-radius: 23.4px;

          img {
            width: 100%;
            height: auto;
          }
        }

        .screen {
          position: relative;

          img {
            display: block;
          }
        }

        .teaser {
          position: absolute;
          line-height: 1.2em;
          background: white;
          padding: 35px 40px;
          box-shadow: 6px 6px 53px rgba(74, 74, 74, 0.1);
          border: 1px solid #dbdbdb;
          border-radius: 5px;

          h3 {
            font-size: 28px;
            margin: 0;
            color: $orange;
          }

          &-01-01 {
            text-align: center;
            width: 280px;
            left: -360px;
            top: 120px;

            p {
              font-size: 12px;
            }
          }

          &-01-02,
          &-02-02 {
            width: 426px;
            right: -360px;
            top: 310px;

            p {
              font-size: 14px;
            }
          }

          &-02-03 {
            width: 426px;
            left: -360px;
            top: 420px;

            p {
              font-size: 14px;
            }
          }
          @include for-phone-only {
            &-01-01 {
              text-align: center;
              width: 280px;
              left: 0;
              top: 400px;

              p {
                font-size: 12px;
              }
            }

            &-01-02,
            &-02-02 {
              width: 280px;
              left: 0;
              top: 500px;

              p {
                font-size: 14px;
              }
            }

            &-02-03 {
              width: 280px;
              left: 0;
              top: 600px;
            }
          }
        }
      }
    }

    .content {
      max-width: 790px;
      margin: 0 1rem;

      h2 {
        text-align: center;
      }

      p {
        font-size: 14px;
        line-height: 1.5em;
      }
    }
  }

  &.start-02 {
    .teaser {
      background: #f2f2f2;
      padding: 40px 30px 20px;

      h3 {
        font-size: 19px;
        font-weight: bold;
        margin: 0;
        color: $text-color;
      }

      .price {
        margin-top: 1rem;
        color: $brand-color;
        font-size: 43px;
        line-height: 1em;
        font-weight: bold;
      }

      ul {
        text-align: left;
        margin: 10px 0 20px;
      }

      li {
        margin-bottom: 8px;
        font-size: 20px;
      }

      .more-info {
        margin-top: 20px;
        color: $text-color;
        font-size: 12px;
      }

      .button {
        width: 100%;
        max-width: 220px;
      }

      p a {
        color: inherit;

        &:hover {
          color: $brand-color;
        }
      }
    }
  }

  &.start-03 {
    h3 {
      color: black;
      font-weight: bold;
      font-size: 16px;
      margin: 0 0 0.5em;
    }

    p {
      margin: 0 0 0.25em;
    }

    .button {
      margin-top: 2em;
      width: 100%;
      max-width: 220px;
    }
  }

  &.start-04 {
    h2 {
      text-align: center;

      strong {
        padding-top: 0.5em;
        font-size: 75px;
        line-height: 1em;
        font-weight: 800;
        color: $text-color;

        @include for-phone-only {
          font-size: inherit;
        }
      }
    }
  }

  &.start-05 {
    padding-top: 6rem;

    .client-logos {
      margin: 0 auto;
      width: 100%;
      max-width: 716px;

      div img {
        margin: 0 auto;
      }

      .slick-prev {
        left: -7%;
      }

      .slick-next {
        right: -7%;
      }
      @include for-phone-only {
        width: 80%;

        div img {
          max-width: 80%;
          height: auto;
        }

        .slick-prev {
          left: -13%;
        }

        .slick-next {
          right: -13%;
        }
      }
    }
  }

  &.start-06 {
    margin: 0 auto;
    text-align: center;
    max-width: 840px;

    img {
      margin-bottom: 1.5rem;
    }

    p {
      text-align: left;
      font-size: 14px;
      line-height: 24px;
    }

    button {
      margin-top: 2rem;
      width: 200px;
    }
  }

  &.start-07 {
    font-size: 15px;

    .link-lists {
      margin: 1rem 0 3rem;
      @include for-phone-only {
        display: block;

        .link-list {
          width: 100%;
        }
      }
    }

    .terms {
      font-style: italic;
      color: #9b9b9b;
    }

    .contactform .button {
      min-width: 150px;
      @include for-phone-only {
        min-width: 100px;
      }
    }
  }

  &.start-08,
  &.start-11,
  &.start-12 {
    flex-direction: row;

    & > div {
      width: 46%;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .image {
      padding-top: 3rem;
      text-align: right;
    }

    .text {
      vertical-align: middle;
      padding: 2em 0 0 7%;

      p, ol, ul {
        font-size: 14px;
        line-height: 22px;
      }

      ul {
        list-style-type: disc;
        margin-left: 1.35em;
      }

      > .button {
        margin-top: 2rem;
        min-width: 180px;
      }
    }
    @include for-phone-only {
      flex-direction: column;

      & > div,
      .text {
        width: unset;
        padding: unset;

        > .button {
          display: block;
          margin: 2rem auto 0;
        }
      }
    }
  }

  &.start-09 {
    display: flex;
    margin: 0 auto;
    text-align: center;
    max-width: 830px;

    .customer-quote {
      font-size: 40px;
      line-height: 51px;
      font-weight: 800;

      @include for-phone-only {
        font-size: 30px;
        line-height: 1.3em;
      }
    }

    .customer {
      font-size: 14px;
      line-height: 21px;
      margin-top: 1rem;

      strong {
        color: black;
        font-size: 16px;
        line-height: 37px;
      }
    }

    img {
      margin: 0 auto;
      height: 50px;
      width: 50px;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  &.start-12 {
    flex-wrap: wrap;

    .text {
      width: 50%;
    }

    .image {
      width: 50%;
      padding-left: 3rem;
      text-align: center;
    }

    @include for-phone-only {
      display: block;

      .text,
      .image {
        width: 100%;
      }

      .image {
        padding: unset;
        text-align: center;
      }
    }

    .contactform {
      margin-top: 3rem;
      
      @include for-phone-only {
        padding-bottom: 2rem;
      }

      .button {
        min-width: 180px;
        @include for-phone-only {
          position: unset;
          margin-top: 8px;
          min-width: 100px;
        }
      }
    }
  }

  .teaser .button {
    width: 72%;
  }

  .col-3 .button {
    min-width: 90px;
  }

  .case-study {
    margin: 2rem auto 0;
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 2em;

    .client {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      flex: 2 0;
      padding-right: 30px;
      border-right: 2px solid #C6C6C6;

      img {
        height: 50px;
        width: 50px;
        border-radius: 100%;
        object-fit: cover;
        margin-left: 10px;
      }

      strong {
        font-size: 16px;
        line-height: 1em;
        color: black;
      }
    }

    .case-study-description {
      flex: 2 0;
      padding-left: 25px;

      p {
        margin: 0;
      }
    }
    @include for-phone-only {
      display: block;

      .client {
        border-right: unset;
        margin-bottom: 1rem;
        text-align: unset;
        padding: unset;
        justify-content: initial;
      }

      .case-study-description {
        padding: unset;
      }
    }
  }

  .link-lists {
    width: 100%;
    max-width: 800px;
    display: flex;
    align-self: center;
    font-size: 14px;
    line-height: 17px;

    @include for-phone-only {
      width: 80%;
    }
  }

  .link-list {
    flex: 1 0 auto;
    width: 33%;

    &:not(:last-child) {
      margin-right: 1.25rem;
    }

    a {
      display: block;
      padding: 1em 1.5em 1em 0;
      border-bottom: 1px solid $brand-color;
      color: $text-color;

      &:hover {
        color: $brand-color;
        text-decoration: none;
      }
    }
  }

  .contactform form,
  .contactform form input[type="text"],
  input[type="email"] {
    width: 100%;
    max-width: 520px;
  }

  @include for-phone-only {
    padding: 3rem 0 5rem;
  }
}
