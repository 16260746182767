

.contactform {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: unset;

    form {
        position: relative;
    }
    .button {
        position: absolute;
        right: 5px;
        top: calc(50% - (36px / 2));
    }

    label {
        font-size: 11px;
    }

    input[type="text"], input[type="email"] {
        text-align: left;
        font-size: 16px;
        background: $mid-grey;
        padding: 0.8em;
        border: none;
        border-radius: 3px;
    }
    .error-text {
        font-size: 11px;
        color: red;
    }
}

