.page-utvecklare main {
  width: 80%;
  max-width: 800px;
  margin: 3em auto;

  article {
    margin: 2.5em 0;
  }

  h1 {
    font-size: 43px;
  }

  h2 {
    margin: 0 0 0.4em;
  }

  h1,
  h2,
  h3 {
    line-height: 1.25em;
  }

  @include for-phone-only {
    max-width: unset;
    margin: 2em 0 0;
  }
}