/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: normal 125% / 1.4 $text-font-stack;
}
h1, h2, h3 {
  line-height: 1.2em;
  font-weight: 300;
  color: $heading-color;
}