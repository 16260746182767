// -----------------------------------------------------------------------------
// This file contains styles that are specific to the tjansten page.
// -----------------------------------------------------------------------------

.tjansten-01 {
  margin: 0 auto;
  text-align: center;
  max-width: 930px;

  img {
    display: block;
    margin: 0 auto 2rem;
    height: 240px;
    width: 240px;
    object-fit: cover;
    border-radius: 100%;
    border: 1px solid #979797;
  }

  h1 {
    font-size: 43px;
    margin: 0 0 0.8em;
  }

  .text {
    margin-bottom: 3em;

    p {
      font-size: 14px;
      text-align: left;
    }
  }

  .contactform .button {
    min-width: 150px;
    @include for-phone-only {
      min-width: 100px;
    }
  }
}

.tjansten-02,
.tjansten-03 {
  flex-direction: row;

  .carousel {
    position: relative;
    min-width: 450px;
  }

  .text {
    font-size: 18px;

    .customer-quote {
      font-weight: bold;
      font-size: 23px;
      line-height: 1.6em;
      margin: 2em 0;
    }

    .customer {
      text-align: center;
      font-size: 15px;

      p {
        margin: 0 0 0.2em;

        img {
          margin-bottom: 0.75em;
        }
      }

      strong {
        color: black;
      }
    }

    img {
      margin: 0 auto;
      height: 50px;
      width: 50px;
      border-radius: 100%;
      object-fit: cover;
    }

    i {
      font-size: 14px;
    }
  }

  .single-item {
    width: 75%;
    max-width: 290px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .slick-slider {
    margin: 0 auto;
  }

  @include for-phone-only {
    display: block;

    .col-2 {
      min-width: unset;
      max-width: 100%;
      width: unset;
    }

    .carousel {
      margin-bottom: 5em;
    }
  }

  @media only screen and (max-width: 424px) {
    .slick-prev {
      left: -15%;
    }

    .slick-next {
      right: -15%;
    }
  }
}

.tjansten-03 .text {
  font-size: 14px;
}

@include for-tablet-portrait-up {
  .tjansten-02 {
    padding-right: 7%;
    padding-left: 3%;
  }

  .tjansten-03 {
    flex-direction: row-reverse;
    padding-right: 3%;
    padding-left: 7%;
  }
}

.tjansten-04 {
  flex-direction: row;

  .image {
    text-align: center;

    img {
      width: 100%;
      max-width: 380px;
      height: auto;
    }
  }

  .text {
    font-size: 18px;
  }
  @include for-phone-only {
    display: block;

    .col-2 {
      min-width: unset;
      max-width: 100%;
      width: unset;
    }
  }
}

.tjansten-05 {
  text-align: center;

  img {
    max-width: 762px;
    width: 100%;
  }
}

.tjansten-06 {
  .table-functions tbody {
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6) {
      border-left: 1px solid #ff7d00;
      border-right: 1px solid #ff7d00;
      width: 50px;
      text-align: center;

      div {
        padding-left: 1em;
      }

      p:before {
        content: "";
        top: 0.45em;
        display: inline-block;
        width: 0.25em;
        height: 0.5em;
        border: solid $text-color;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  tr:last-child {
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6) {
      border-bottom: 1px solid #ff7d00;
      border-radius: 7px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  @media only screen and (max-width: 900px) {
    padding-right: 0;
    padding-left: 0;
  }

  @media only screen and (max-width: 820px) {
    .table-functions {
      th.rotate div {
        line-height: 36px;
      }

      tbody td:nth-child(3),
      tbody td:nth-child(4),
      tbody td:nth-child(5),
      tbody td:nth-child(6) {
        width: 37px;
      }
    }
  }

  @media only screen and (max-width: 766px) {
    .table-functions {
      position: relative;
      margin-top: 2em;

      thead {
        position: absolute;
        display: flex;
        justify-content: center;
        top: -2em;
        width: 100%;

        tr {
          display: flex;
        }

        th,
        th.rotate {
          height: unset;
          display: inline-block;

          div {
            border: unset;
            padding: unset;
            line-height: unset;
            position: unset;
            height: unset;
            padding: 0 10px;
            writing-mode: unset;
            transform: unset;
          }
        }

        th:nth-child(1),
        th:nth-child(2) {
          display: none;
        }
      }

      tbody {
        tr:nth-child(odd) {
          background: unset;
        }

        td:nth-child(3),
        td:nth-child(4),
        td:nth-child(5),
        td:nth-child(6) {
          display: none;
        }

        td {
          max-width: 100%;
          padding-right: 0;
          padding-left: 0;
          display: block;

          &:nth-child(even) {
            padding-top: 0;
            border-bottom: #eaeaea solid 1px;
          }

          &:nth-child(odd) {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
