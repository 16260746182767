// -----------------------------------------------------------------------------
// This file contains all styles related to the navigation components.
// -----------------------------------------------------------------------------

nav ul {
  li {
    display: inline-flex;

    a:hover {
      text-decoration: none;
    }
  }
}

#toggleNavMain,
#toggleNavUser {
  position: absolute;
  visibility: hidden;
}

@media only screen and (min-width: 768px) {
  nav ul {
    display: flex;
  }

  .nav-main {
    &-item__help a,
    &-item__user a {
      &:before {
        display: inline-block;
        margin-bottom: -0.3em;
        margin-right: 8px;
        content: "";
        height: 16px;
        width: 16px;
        background-size: 16px auto;
      }
    }

    &-item_home-link {
      display: none;
    }

    &-item__help a:before {
      background: transparent url(/images/icon-search.svg) 50% 0 no-repeat;
    }

    &-item__user {
      a:before {
        background: transparent url(/images/icon-user.svg) 50% 0 no-repeat;
      }
    }

    &-item__right {
      margin-left: auto;
    }
  }

  .user-link {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  header {
    flex-direction: column;

    .toggle-nav-main {
      position: absolute;
      top: 1.2rem;
      z-index: 3;
      align-items: center;
      cursor: pointer;
      display: block;
      width: 25px;
      height: 17px;
      left: 1.2rem;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;

      &::before {
        content: '';
        display: block;
        position: relative;
        top: calc(50% - 1px);
        border-top: 1px solid #000;
      }
    }

    .toggle-nav-user {
      display: none;
    }
  }

  .nav-main {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 2;
    top: -16em;
    height: 0;
    overflow: hidden;
    left: 0;
    width: 100%;
    transition: top 100ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    background: rgba(255, 255, 255, 0.9);

    li {
      display: flex;

      a {
        flex: 1;
        font-size: 2.5em;
        padding: 0.5em;
        border: 0;
      }
    }
  }

  .user-link {
    position: absolute;
    top: 0.9rem;
    height: 22px;
    right: 1.1rem;
    background: transparent url(/images/icon-user.svg) 50% 0 no-repeat;
    background-size: 22px auto;
    width: 22px;
    text-indent: -9999px;

    a:before {
      background: transparent url(/images/icon-user.svg) 50% 0 no-repeat;
    }
  }

  #toggleNavMain {
    &:checked ~ .nav-main {
      top: 0;
      height: 100vh;
    }
  }

  #toggleNavUser {
    &:checked ~ .nav-user {
      top: 0;
      height: 100vh;
    }
  }
}